import * as React from 'react';
import styled from 'styled-components';

import StyledContainer from './organisms/StyledContainer.js';
import CategorySection from './templates/CategorySection.js';

import Grinders from '../assets/images/category_images/grinders.png';
import Pipes from '../assets/images/category_images/pipes.png';
import RollPapers from '../assets/images/category_images/rollingPapers.png';
import Topicals from '../assets/images/category_images/topicals.png';
import Teas from '../assets/images/category_images/drinks.png';
import SoftDrinks from '../assets/images/category_images/softDrinks.png';
import Water from '../assets/images/category_images/beverages.png';
import Gummies from '../assets/images/category_images/gum.png';
import Chocolate from '../assets/images/category_images/chocolate.png';
import OilCBD from '../assets/images/category_images/oilsCBD.png';
import OilTHC from '../assets/images/category_images/oilsTHC.png';
import Vaporizers from '../assets/images/category_images/vaporizers.png';
import VapePens from '../assets/images/category_images/vapePens.png';
import Cartridges from '../assets/images/category_images/vapeCartridges.png';

const MOBILE_BREAKPOINT = '1000px';

const allProductsData = {
  accessories: [
    {
      src: Grinders,
      title: 'Weed Grinders',
    },
    {
      src: Pipes,
      title: 'Weed Pipes',
    },
    {
      src: RollPapers,
      title: 'Rolling Papers',
    },
    {
      src: Topicals,
      title: 'Cannabis Topicals',
    },
  ],
  beverages: [
    {
      src: Teas,
      title: 'Weed Teas',
    },
    {
      src: SoftDrinks,
      title: 'Cannabis soft drinks',
    },
    {
      src: Water,
      title: 'Weed Water',
    },
  ],
  edibles: [
    {
      src: Gummies,
      title: 'Edible Gummies',
    },
    {
      src: Chocolate,
      title: 'Edible Chocolate Bars',
    },
  ],
  oils: [
    {
      src: OilCBD,
      title: 'CBD Oils',
    },
    {
      src: OilTHC,
      title: 'THC Oils',
    },
  ],
  vaporizers: [
    {
      src: Vaporizers,
      title: 'Desktop Weed Vaporizers',
    },
    {
      src: VapePens,
      title: 'Vape Pens',
    },
    {
      src: Cartridges,
      title: 'Vape Cartridges',
    },
  ],
};

const Container = styled.div`
  margin-top: 120px;
  margin-bottom: 40px;
  text-align: center;
`;

const BrandLocations = () => (
  <Container>
    <CategorySection
      data={allProductsData.accessories}
      columns="4"
      title="Shop Weed Accessories"
    />
    <StyledContainer breakpoint={MOBILE_BREAKPOINT}>
      <CategorySection
        data={allProductsData.beverages}
        columns="3"
        title="Shop Weed Beverages"
      />
      <CategorySection
        data={allProductsData.edibles}
        columns="2"
        title="Shop Cannabis Edibles"
      />
      <CategorySection
        data={allProductsData.oils}
        columns="2"
        title="Shop Cannabis Oils"
      />
      <CategorySection
        data={allProductsData.vaporizers}
        columns="3"
        title="Shop Weed Vaporizers"
      />
    </StyledContainer>
  </Container>
);

export default BrandLocations;

import * as React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';

import StyledLink from './organisms/StyledLink.js';

import BackgroundGuelph from '../../static/backgrounds/Guelph.png';
import BackgroundCambridge from '../../static/backgrounds/Cambridge.png';

const MOBILE_BREAKPOINT = '768px';

const Container = styled.div`
  margin-top: 120px;
  margin-bottom: 40px;
  text-align: center;
`;

const Title = styled.h2`
  position: relative;
  z-index: 5;
  width: 60%;
  margin: 0 auto 10px;
  padding: 0 50px;
  font-family: 'D-DINExp', serif;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 120%;
  letter-spacing: 0.2px;
  color: black;

  @media screen and (max-width: 1200px) {
      width: 100%;
  }

  @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
      font-size: 24px;
  }
`;

const LocationContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));

  .brush-stroke {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 140px;
      transform: translateY(-85px);
  }

  @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
    grid-template-columns: minmax(0, 1fr);
  }
`;

const Location = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 35% 10px 90px;
  text-align: center;
  color: white;
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.8) 100%
    ),
    url(${(props) => props.src});
  background-repeat: no-repeat;
  background-size: cover;

  @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
    padding: 150px 0;
  }
`;

const LocationName = styled.h3`
  margin-bottom: 50px;
  font-family: 'Gloss-And-Bloom', serif;
  font-style: normal;
  font-weight: normal;
  font-size: 64px;
  line-height: 120%;
  letter-spacing: 0.2px;

  @media screen and (min-width: ${MOBILE_BREAKPOINT}) and (max-width: 1200px) {
    font-size: 5vw;
  }
`;

const BrandLocations = () => (
  <Container>
    <Title>
      Visit One of Our Locations to See What Treats We've Got in Stock
    </Title>
    <LocationContainer>
      <Location src={BackgroundGuelph}>
        <LocationName>Guelph</LocationName>
        <StyledLink primary>location</StyledLink>
      </Location>
      <Location src={BackgroundCambridge}>
        <LocationName>Cambridge</LocationName>
        <StyledLink primary>location</StyledLink>
      </Location>
      <StaticImage
        src="../../../static/svg/BrushStroke.svg"
        alt=""
        className="brush-stroke brush-stroke--top"
      />
    </LocationContainer>
  </Container>
);

export default BrandLocations;

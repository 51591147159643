import React from 'react';
import styled from 'styled-components';

import StyledLink from './organisms/StyledLink.js';

const MOBILE_BREAKPOINT = '1000px';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 20px 18px;
  text-align: center;
  font-family: 'D-DINExp', serif;
  font-size: 30px;
  font-style: normal;
  font-weight: bold;
  background: #ffffff;
  box-shadow: 0 4px 15px 5px rgba(60, 100, 177, 0.1);
  border-radius: 3px;

  @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
    min-width: 290px;
    min-height: 290px;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h5`
    margin-bottom: 15px;
`;

const CategoryCard = ({ categoryImage, categoryTitle, categorySlug }) => {
    console.log(categorySlug)

    return(
        <Container>
            <ImageContainer>
            <img src={categoryImage} alt="" />
            </ImageContainer>
            <Title>{categoryTitle}</Title>
            {/* <StyledLink to={`${categorySlug}&dtche%5Blocation%5D=ronin-cannabis`}>Shop Category</StyledLink> */}
            <StyledLink to={`/locations`}>Shop Category</StyledLink>
        </Container>
    )
};


export default CategoryCard;

import * as React from "react"
import styled from 'styled-components'

import CategoryCard from "../CategoryCard.js"


const MOBILE_BREAKPOINT = '1000px';

const Container = styled.div`
    margin-top: 50px;
    margin-bottom: 120px;
    text-align: center;

    padding-top: 50px;

    @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
        margin-bottom: 70px;
    }
`;

const Subtitle = styled.h4`
    padding: 4vh 25vw;
`

const Title = styled.h2`
    max-width: 980px;
    margin: 0 auto;
    margin-bottom: 23px;
    padding: 0 20px;
    font-family: 'Gloss-And-Bloom';
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    letter-spacing: 0.2px;
    color: black;
`;

const Description = styled.div`
    max-width: 860px;
    margin: 0 auto;
    margin-bottom: 60px;
    padding: 0 20px;
    font-family: 'D-DINExp';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.3px;
    color: #6D6E71;
`;

const CardContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(${props => props.columns}, 1fr);
    grid-auto-rows: 1fr;
    grid-gap: 20px;
    padding: 0 20px;

    @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
        display: flex;
        padding: 20px;
        overflow-x: scroll;

        &::-webkit-scrollbar {
            display: none;
        }

        --ms-overflow-style: none;
        scrollbar-width: none;
    }
`;

export default function CategorySection(props){
    const sub = props.subtitle;
    return(
        <Container>
            {sub ? <Subtitle>{sub}</Subtitle> : <div></div>}
            <Title>{props.title}</Title>
            <Description>
                {props.description && props.description.map((item) => (
                    <p>{item}</p>
                ))}
            </Description>
            <CardContainer columns={props.columns}>
                {props.data.map((item) => (
                    <CategoryCard
                        categoryImage={item.src}
                        categoryTitle={item.title}
                        categorySlug={item.slug}
                    />
                ))}
            </CardContainer>
        </Container>
    )
}


import React from 'react';
import { graphql } from 'gatsby';
import BrandGrid from '../components/BrandGrid';
import BrandsHero from '../components/BrandHero';
import BottomHero from '../components/BottomHero';
import SEO from '../components/SEO';
import BrandLocations from '../components/BrandLocations';
import BrandAllProducts from '../components/BrandAllProducts';
import CategorySection from '../components/templates/CategorySection';

import Accessories from '../assets/images/category_images/grinders.png';
import Drinks from '../assets/images/category_images/drinks.png';
import Edibles from '../assets/images/category_images/edibles_2.png';
import Vapes from '../assets/images/category_images/vapes.png';
import Topicals from '../assets/images/category_images/topicals.png';
import Oils from '../assets/images/category_images/oils.png';


const categoriesData = [
  {
    src: Accessories,
    title: "Grinders",
    slug: "/livemenu?dtche[subcategories]=grinders&dtche[category]=accessories",
  },
  {
      src: Edibles,
      title: 'Edibles',
      slug: '/livemenu?dtche[category]=edibles',
  },
  {
      src: Vapes,
      title: 'Vapes',
      slug: '/livemenu?dtche[category]=vaporizers',
  },
  {
    src: Topicals,
    title: "Cannabis Topicals",
    slug: "/livemenu?dtche[category]=topicals",
},
  {
      src: Oils,
      title: 'Oils',
      slug: '/livemenu?dtche[category]=concentrates',
  },
  {
      src: Drinks,
      title: 'Beverages',
      slug: '/livemenu?dtche[subcategories]=drinks&dtche[category]=edibles',
  },
];

const Brands = ({ data }) => {
  const branddata = data.branddata.nodes;

  return (
    <>
      <SEO 
        title="Cannabis Brands"
        description="We're able to prodvide top-quality cannabis buds, as well as an 
        amazing selection of weed accessories by partnering with the most reliable, legal cannabis 
        brands across Canada. Every product we provide is regulated by the Government 
        of Canada to ensure it's safe to use and legal for you to purchase."
      />
      <BrandsHero />
      <BrandGrid branddata={branddata} />
      <CategorySection
        data={categoriesData}
        columns="3"
        title="Cannabis Products Made by the Best Brands"
      />
      <BrandLocations />
      <BrandAllProducts />
      <BottomHero
        title={"Ontario's #1 Cannabis Brands"}
        content={[
          `When you're looking to buy the best indica or sativa strains, you want to know that 
                    they're coming from cannabis brands that you can trust.`,

          `Ronin Cannabis specializes in providing top-quality cannabis buds, as well as an 
                    amazing selection of weed accessories. We take pride in ensuring that our customers 
                    always leave our stores with cannabis products that are going to help facilitate 
                    the exact kind of cannabis experience they're looking to find.`,

          `We're able to achieve this by partnering with the most reliable, legal cannabis 
                    brands across Canada. Every product we provide is regulated by the Government 
                    of Canada to ensure it's safe to use and legal for you to purchase.`,

          `The most enjoyable cannabis experiences come from the greatest cannabis products, 
                    and you'll find those at Ronin Cannabis.`,
        ]}
      />
    </>
  );
};

export const query = graphql`
  query {
    branddata: allSanityBrandsContent {
      nodes {
        imagePlusAltHeader {
          alt
          image {
            asset {
              _id
            }
          }
        }
        name
      }
    }
  }
`;

export default Brands;

import React from 'react';
import styled from 'styled-components';
import BrandTile from './BrandTile';

const Wrapper = styled.div`
  background-color: black;
  width: 100%;
  min-height: 70vh;
`;

const BrandGridStyle = styled.div`
  padding: 50px 10px;
  padding-top: 0px;

  .p-0 {
    padding: 0;
  }

  width: 1500px;
  position: relative;
  left: 50%;
  margin-left: -750px;
  @media (max-width: 1500px) {
    width: 1000px;
    margin-left: -500px;
  }
  @media (max-width: 1000px) {
    width: 100%;
    left: 0;
    margin: 0 auto;
    position: absolute;
  }
`;

const BrandGrid = ({ branddata }) => (
  <Wrapper>
    <BrandGridStyle>
      <div className="container-fluid">
        <div className="row">
          {branddata.map((brand) => (
            <div className="col-lg-3 col-4 p-0">
              <BrandTile
                brandname={brand.name}
                image={brand.imagePlusAltHeader.image}
                desrc={brand.desrc}
                alt={brand.imagePlusAltHeader.alt}
              />
            </div>
          ))}
        </div>
      </div>
    </BrandGridStyle>
  </Wrapper>
);

export default BrandGrid;

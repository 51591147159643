import React from 'react';
import styled from 'styled-components';
import { getGatsbyImageData } from 'gatsby-source-sanity';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import clientConfig from '../../client-config';

const BrandTileStyle = styled.div`
  background-color: white;
  height: 15vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 5px;
  padding: 20px 20px;
  border-radius: 3px;
  @media (max-width: 1000px) {
    height: 25vw;
  }
`;

const BrandTile = ({ image, alttext }) => {
  const gatsbyImageData = getGatsbyImageData(
    image.asset,
    { maxWidth: 100 },
    clientConfig.sanity
  );
  const readyImage = getImage(gatsbyImageData);

  return (
    <BrandTileStyle>
      <GatsbyImage image={readyImage} alt={alttext} />
    </BrandTileStyle>
  );
};

export default BrandTile;

import React from 'react';
import styled from 'styled-components';

import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';

import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';

const HeroStyle = styled.div`
  .hero-image,
  .hero-image::before,
  .hero-image::after {
    padding-top: 20vh;
    min-height: 85vh;
  }

  .brushStroke {
    position: absolute;
    bottom: 0;
    width: 100%;
    margin-bottom: -5vh;
  }

  @media (max-width: 1000px) {
    .hero-image,
    .hero-image::before,
    .hero-image::after {
      background-position-y: center;
      background-position-x: 45%;
      padding-top: 12vh;
      height: 80vh;
    }

    .brushStroke {
      display: none;
    }
  }
`;

const HeroText = styled.div`
  padding: 30px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  p {
    color: white;
    opacity: 0.7;
    max-width: 60vw;
  }

  @media (max-width: 1000px) {
    p {
      max-width: 100%;
    }
  }
`;

export default function BrandsHero() {
  // ----Gatsby Background Image Set-Up-----
  const { bannerImage } = useStaticQuery(
    graphql`
      query {
        bannerImage: file(relativePath: { eq: "brandHero.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1000
              quality: 90
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  );
  const image = getImage(bannerImage);
  const bgImage = convertToBgImage(image);

  return (
    <HeroStyle>
      <BackgroundImage
        Tag="div"
        {...bgImage}
        preserveStackingContext
        className="hero-image"
        style={{
          width: '100%',
          height: '',
          backgroundPosition: '',
        }}
      >
        <HeroText>
          <h1>Cannabis Brands</h1>
          <p>
            With so many cannabis brands available already and more popping up
            all the time, finding a brand that produces the best weed strains to
            fit your style can take a lot of trial and error.
            <br /> <br />
            Thankfully, Ronin Cannabis has partnered with the most reliable and
            best cannabis brands to offer you a huge selection of cannabis buds
            that are sure to please even the pickiest cannabis users.
          </p>
        </HeroText>
        <img src="svg/BlackBrushStroke.svg" className="brushStroke" alt="" />
      </BackgroundImage>
    </HeroStyle>
  );
}
